
.page-section#get-in-touch {
  @include media-breakpoint-down(md) {
    padding-top: 40vw;
  }
}

.form-holder-section {
  p {}

  h3 {
    margin-top: 35px;
    margin-bottom: 25px;
    font-weight: 700;

    i {
      margin-right: 20px;
    }
  }
}

.contact-form {
  label {
    color: #fff;
    margin-bottom: 2px;
    line-height: 1;
    margin-top: 35px;
  }

  .field-errors {
    font-size: 0.8rem;
    padding: 4px;
    color: #ff93a8;
    letter-spacing: 0.3px;
  }

  input.form-control, textarea.form-control {
    border: none;
    background-color: $purple-dark;
    color: #fff;

    &:focus {
      background-color: $purple-dark;
      outline: none;
      border: none;
    }

    &.has-error {
      border: 1px #DC143C solid;
    }
  }

  .multiselect {
    border: none;
    background-color: $purple-dark;
    color: #fff;
    border-radius: 0.25rem;
    &__tags {
      border: none !important;
      border-radius: 0.25rem !important;
      background-color: $purple-dark;
      color: #fff;
    }
    &__content {
      background-color: $purple-dark;
    }
    &.has-error {
      border: 1px #DC143C solid;
    }
  }

  .submit-indicator {
    color: #fff;
  }

  .submit-message {
    color: #fff;
    &.submit-errors {
      padding: 15px;
      color: #DC143C;
      border: 2px solid #DC143C;
      background: #ff93a8;
    }
  }
}
