.select-style.form-control {
  border: none;
  width: 100%;
  overflow: hidden;
  background-color: $purple-dark;
  color: #fff;
  position: relative;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0;

  &:focus {
    background-color: $purple-light;
    outline: none;
    border: none;
  }

  &.has-error {
    border: 1px #DC143C solid;
  }

  select {
    width: 100%;
    border: none;
    box-shadow: none;
    background-image: none;
    -webkit-appearance: none;
    color: #fff;
    background-color: $purple-dark;
    padding: 0.375rem 0.75rem;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  &:after {
    font-family: FontAwesome;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    content: "\f078";
    font-size: 0.7rem;
    color: #aaa;
    pointer-events: none;
  }
}




